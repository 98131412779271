<template>
  <div class="content">
    <van-overlay :show="show">
      <div class="wrapper">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { Loading, Overlay } from 'vant'
export default {
  name: 'Loading',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {},
  watch: {},
  created() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
