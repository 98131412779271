<template>
  <marquee behavior="scroll" direction="up" scrollamount="3" :style="{ height: `calc(${height}rem / 16)` }">
    <div v-for="(item, index) in noticeArrss" :key="`marquee_${index}`">
      <slot :item="item"></slot>
    </div>
  </marquee>
</template>

<script>
import dataDetail from "@/utils/dataDetail";
import { randomUsername } from "@/utils/randomUsername";
export default {
  props: {
    height: {
      type: Number,
      default: 208,
    },
  },
  data: () => ({
    noticeArrss: [],
  }),
  created() {
    this.noticeIni();
  },
  methods: {
    // add:lunbo
    noticeIni() {
      for (let i = 0; i < 100; i++) {
        const randMoneyType = this.randMoneyType();
        let money = this.randMoney();
        let moneyType = this.verConfig.moneyType;
        if (typeof randMoneyType === "object") {
          money = randMoneyType.money;
          moneyType = randMoneyType.symbol;
        }
        this.noticeArrss.push({
          name: this.verConfig.useNewUsername ? randomUsername({ includes: ["number", "lower", "upper"], len: 4 }) + "****" : this.randName(),
          money,
          moneyType
        });
      }
    },
    randMoneyType() {
      if (this.verConfig.randMoneyTypes) {
        const index = dataDetail.rand(0, this.verConfig.randMoneyTypes.length);
        // return this.verConfig.randMoneyTypes[index].symbol;
        const { symbol } = this.verConfig.randMoneyTypes[index];
        const money = this.randMoneyFun(this.verConfig.randMoneyTypes[index].interval);
        return {
          symbol,
          money
        }
      }
      return this.verConfig.moneyType;
    },
    // add:lunbo
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArrss.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    // add/随机名字
    randName() {
      let startNum = 0;
      if (this.verConfig.hallPhonenumStart) {
        if (Array.isArray(this.verConfig.hallPhonenumStart)) {
          startNum = dataDetail.rand(
            this.verConfig.hallPhonenumStart[0],
            this.verConfig.hallPhonenumStart[1]
          );
        } else {
          startNum = this.verConfig.hallPhonenumStart;
        }
      }
      let name =
        `+${this.verConfig.areaCode}\n` +
        String(
          startNum
            ? `${startNum}${dataDetail.rand(0, 9)}`
            : dataDetail.rand(20, 99)
        ) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    randMoneyFun(interval) {
      const num = Math.round(Math.random() * interval[1]);
      if (num < interval[0]) {
        return this.randMoneyFun(interval);
      }
      return num;
    },
    // add/随机
    randMoney() {
      const { homeProfitIntervalMax, homeProfitInterval } = this.verConfig;
      let interval = homeProfitInterval || [100, 300000];
      if (homeProfitIntervalMax) {
        if (dataDetail.rand(0, 10) < 2) {
          interval = homeProfitIntervalMax;
        }
      }
      let probability = dataDetail.rand(0, 100);
      let res = 0;
      if (probability <= 30) {
        // if(this.verConfig.homeProfitFormat){
        //   return this.$money(dataDetail.rand(interval[0], interval[1] / 20))
        // }
        return dataDetail.rand(interval[0], interval[1] / 20) * 100000;
      } else {
        // if(this.verConfig.homeProfitFormat){
        //   return this.$money(dataDetail.rand(interval[1] / 20, interval[1]))
        // }
        return dataDetail.rand(interval[1] / 20, interval[1]) * 10000;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>